<template>
    <div class="container">
        <s-header :name="'售后/退款'" @callback="close"></s-header>
        <div class="good-list" v-if="detail.goodsList.length">
            <div class="good-head">商品详情</div>
            <div class="good-item" v-for="(item, index) in detail.goodsList" :key="index">
                <div class="good-item-lt">
                    <img :src="item.picUrl" alt="">
                    <div class="good-item-info">
                        <div class="good-item-name">{{ item.name }}</div>
                        <span v-if="item.specifications.length" class="good-item-sku">{{ item.specifications.join()
                        }}</span>
                        <div class="good-item-num">x{{ item.remaining }}</div>
                    </div>
                </div>
                <div class="good-item-price">¥{{ item.price }}</div>
            </div>
            <div class="stepper" v-if="(type == 2)">
                <span class="text_3">退款数量</span>
                <div>
                    <van-stepper :min="1" :max="detail.goodsList[0].remaining" v-model="number"
                        input-width="30px" button-size="20px" />
                </div>
            </div>
        </div>
        <div class="section">
            <div class="text_1">退款信息</div>
            <div class="group_1">
                <div class="item" @click="show = true">
                    <span class="text_3">退款原因</span>
                    <div class="group_2">
                        <span class="text_2">{{ content || '请选择' }}</span>
                        <img class="img_icon" src="../assets/order10.png" alt="">
                    </div>
                </div>
                <div class="item">
                    <span class="text_3">退款金额</span>
                    <div>
                        <span class="price">¥{{ totalPrice }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section_1">
            <div class="group_3">
                <img src="../assets/order7.png" class="icon_edit" alt="">
                <textarea v-model="backReason" placeholder="补充描述，有助于商家更好的处理售后问题" class="textarea"></textarea>
            </div>
            <div class="img_list">
                <van-uploader v-model="fileList" :max-count="3"/>
            </div>
        </div>
        <div class="order_footer">
            <div class="button button_1" @click="onRefund">退款</div>
        </div>
        <van-action-sheet cancel-text="取消" v-model:show="show" :actions="actions" @select="onSelect" />
    </div>
</template>

<script>
import {
    reactive,
    toRefs,
    ref,
    onMounted,
    computed
} from 'vue'
import sHeader from '@/components/SimpleHeader'
import {
    getOrderDetail,
    confirm_after_sale_applicationreceipt,
    group_order_refund,
    getOSSToken
} from '@/service/order'
import {
    Dialog,
    Toast,
    ImagePreview,
} from 'vant'
import {
    useRoute,
    useRouter
} from 'vue-router'
import { upload } from '../utils/index'
export default {
    components: {
        sHeader
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            type: 1, //1整单 2单独
            detail: {
                goodsList: []
            },
            oid: route.query.oid, //订单id
            gid: route.query.gid, //商品id
            number: 1,
            content: "",
            refundType: "",//原因类型
            backReason: "", //原因详情,
            aliData: {}
        })
        const fileList = ref([])
        const show = ref(false);
        let {type, oid} = route.query;
        state.type = type;
        const actions = [
            { name: '多拍、错拍、不想要', value: 1 },
            { name: '不喜欢、效果不好', value: 2 },
            { name: '货物与描述不符', value: 3},
            { name: '质量问题', value: 4 },
            { name: '收到商品少件、破损、污渍', value: 5 },
            { name: '商家发错货', value: 6 },
            { name: '其他', value: 0 },
        ];
        let totalPrice = computed(() => {
            let total = 0;
            state.detail.goodsList.forEach(item => {
                if(type == 1) {
                    total += item.price * item.reserveNum;
                } else {
                    total += item.price * state.number;
                }
            })
            return total.toFixed(2);
        })
        onMounted(() => {
            getOssData();
            if (route.query.good) {
                let data = JSON.parse(route.query.good);
                data.remaining = data.reserveNum - data.refundFinishNum - data.refundNum;
                state.detail.goodsList.push(data)
            } else {
                init()
            }
        })

        const getOssData = () => {
            getOSSToken().then(res=>{
                if(res.errno == 0) {
                    state.aliData = res.data;
                }
            })
        }

        const init = () => {
            Toast.loading({
                message: '加载中...',
                forbidClick: true
            });

            getOrderDetail({
                orderId: state.oid
            }).then(res => {
                Toast.clear()
                if (res.errno == 0) {
                    state.detail = res.data;
                    res.data.goodsList.forEach(item => {
                        item.remaining = item.reserveNum - item.refundFinishNum - item.refundNum + item.rejectRefundNum;
                    })
                }
            })
        }

        const close = () => {
            Dialog.close()
        }

        const onSelect = (item) => {
            console.log(item);
            show.value = false;
            state.refundType = item.value;
            state.content = item.name;
        };

        const onRefund = () => {
            if (state.content == "") return Toast("请选择退款原因");
            Toast.loading({
                message: '退款提交中',
                duration: 0,
                overlay: true
            })
            if (state.type == 1) { //整单退款
                group_order_refund(state.oid).then(res => {
                    if (res.errno == 0) {
                        Toast("提交成功");
                        setTimeout(() => {
                            router.back();
                        }, 1500)
                    }
                })
            } else { //单独退款
                //有图片退款
                if (fileList.value.length) {
                    let files = fileList.value.filter(item => item.file);
                    let imgs = files.map(file => upload(file.file));
                    Promise.all(imgs).then(images => {
                        console.log(images);
                        confirm_after_sale_applicationreceipt({
                            backNum: state.number,
                            backPicList: images,
                            backReason: state.backReason,
                            orderGoodsId: state.gid,
                            orderId: state.oid,
                            refundType: state.refundType,
                        }).then(res => {
                            if (res.errno == 0) {
                                Toast("提交成功");
                                setTimeout(() => {
                                    router.back();
                                }, 1500)
                            }
                        })
                    })
                } else {
                    confirm_after_sale_applicationreceipt({
                        backNum: state.number,
                        backPicList: [],
                        backReason: state.content,
                        orderGoodsId: state.gid,
                        orderId: state.oid,
                        refundType: state.refundType,
                    }).then(res => {
                        if (res.errno == 0) {
                            Toast("提交成功");
                            setTimeout(() => {
                                router.back();
                            }, 1500)
                        }
                    })
                }
            }
        }

        return {
            ...toRefs(state),
            show,
            actions,
            fileList,
            totalPrice,
            onSelect,
            close,
            onRefund,
        }

    }
}
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: #f8f8f8;
}

.good-list {
    background: #fff;
    padding: 20px 20px 0px 20px;
    margin-top: 10px;
}

.good-head {
    color: #999999;
    font-size: 13;

}

.good-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #fff;
    padding: 10px 0px;

    &-lt {
        display: flex;
        width: 90%;

        // align-items: center;
        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            background: #e5e5e5;
            margin-right: 10px;
        }
    }

    &-info {
        width: calc(100% - 60px);
        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
    }

    &-name {
        color: #333333;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-sku {
        // width: 40px;
        // min-width: 40px;
        display: inline-block;
        text-align: center;
        background-color: #f5f5f5;
        padding: 0 8px;
        margin: 8px 0;
    }

    &-num {
        font-size: 12px;
    }

    &-price {
        width: 10%;
        text-align: right;
        color: #999;
    }
}

.stepper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
    padding: 15px 0;

    .text_3 {
        color: #999;
    }
}

.section {
    width: 92%;
    margin: 0 auto;

    .text_1 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
    }

    .group_1 {
        border-radius: 6px;
        background-color: #fff;

        .item {
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 20px;
            border-bottom: 1px solid #f9f9f9;

            .img_icon {
                width: 6.5px;
                height: 11.5px;
                margin-left: 10px;
            }

            .price {
                color: #FF3B30;
            }

            .group_2 {
                display: flex;
                align-items: center;
            }

            .text_2,
            .text_3 {
                color: #999;
            }

            .text_3 {
                &::after {
                    content: '*';
                    color: red;
                    margin-left: 6px;
                }
            }

        }
    }

    .group_3 {
        width: 100%;
        display: flex;

        .icon_edit {
            width: 19.5px;
            height: 19.5px;
        }

        .textarea {
            width: 100%;
            height: 100px;
            border: none;
        }
    }

    .img_list {
        width: 100%;
        height: 100px;
        background-color: #fff;
    }
}

.section_1 {
    margin: 10px auto 20px auto;
    border-radius: 6px;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    background-color: #fff;
}

.van-cell {
    color: #999;
    font-size: 12px;

    &/deep/.van-cell__title {
        &::after {
            content: '*';
            color: red;
            margin-left: 10px;
        }
    }

    .price {
        color: #FF3B30;
    }
}

.order_footer {
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    left: 0;

    .button {
        width: 91px;
        height: 32px;
        border-radius: 15.5px;
        text-align: center;
        line-height: 32px;
        color: #999;
        font-size: 14px;
        background: linear-gradient(180deg, #e9192e, #fc5d3e);
        color: #fff;
        border: none;
        margin-left: 16px;
    }
}
</style>