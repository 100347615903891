import { getWxPay, getOSSToken } from "../service/order"
import {
	getWxConfig
} from '@/service/user'
import { Toast } from 'vant'
import axios from 'axios'
const initWxJSSDK = () => {
    let url = window.location.href.split('#')[0];
    getWxConfig(url).then(res => {
        let { appId, nonceStr, signature, timestamp } = res.data;
        wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId, // 必填，公众号的唯一标识
            timestamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature, // 必填，签名
            jsApiList: ["chooseWXPay"] // 必填，需要使用的 JS 接口列表
        });
        wx.ready(function () {
            // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
            console.log('wx.config验证成功===');
        });
        wx.error(function (res) {
            console.log('wx.error===', res);
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        });
    })
}
export const getWXPay = (orderid) => {
    return new Promise((resolve, reject) => {
        let url = window.location.href.split('#')[0];
        getWxConfig(url).then(res => {
            let { appId, nonceStr, signature, timestamp } = res.data;
            wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature, // 必填，签名
                jsApiList: ["chooseWXPay"] // 必填，需要使用的 JS 接口列表
            });
            wx.ready(function () {
                // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
                console.log('wx.config验证成功===');
                getWxPay(orderid).then(res => {
                    if (res.errno == 0) {
                        let { timeStamp, nonceStr, packageValue, signType, paySign } = res.data;
                        wx.chooseWXPay({
                            timestamp: timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                            package: packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                            paySign: paySign, // 支付签名
                            success: function (res) {
                                console.log('支付成功');
                                resolve()
                            },
                            fail: function (err) {
                                console.log('支付失败', err);
                                return Toast(err.errMsg)
                            }
                        });
                    }
                })
            });
            wx.error(function (res) {
                console.log('wx.error===', res);
                // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
            });
        })
    })
}

export const upload = (file) => {
    return new Promise((resolve, reject) => {
        getOSSToken().then(res => {
            if (res.errno == 0) {
                let { dir, policy, ossaccessKeyId, signature, host } = res.data;
                let index = file.type.lastIndexOf('/');
                let str = file.type.substr(index + 1, file.type.length - 1)
                var fileurl = dir + new Date().getTime() + '.' + str
                let param = new FormData();
                param.append('key', fileurl);
                param.append('policy', policy);
                param.append('OSSAccessKeyId', ossaccessKeyId);
                param.append('success_action_status', '200');
                param.append('signature', signature);
                param.append('file', file);
                let config = {
                    headers: {
                        'X-Mall-Admin-Token': localStorage.getItem('token')
                    }
                };
                axios.post(host, param, config).then(response => {
                    resolve(host + fileurl)
                })
            } else {
                Toast('上传失败')
                reject()
            }
        }).catch(err => {
            console.log(err);
            Toast('连接异常，上传失败')
            reject()
        })
    })
}